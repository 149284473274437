import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import "./App.css"
import "./css/navbar.css"


import Index from './pages/Index';
import Booking from './pages/booking';
import Info from './pages/info';
import Meet from './pages/meet';
import Seeteams from './pages/seeteams';
import { FaBars } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';


function ScrollToTop() {
  const { pathname } = useLocation();





  useEffect(() => {
    window.scrollTo(0, 0);




  }, [pathname]);

  return null;
}


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <div className='navbar'>
          <div className="logo">
            <img src="https://yourgutmap.co.uk/wp-content/uploads/2023/03/ygm-logo.png" alt="" />

          </div>

          <input id='navbarcheckbox' type='checkbox'/>
         
          <label for="navbarcheckbox" className='navbaricons open'>
            <FaBars style={{ width: '30px', height: '30px' }} />
          </label>
          <label for="navbarcheckbox" className='navbaricons close'>

            <ImCross style={{ width: '30px', height: '30px' }} />
          </label>


          <div className="bar">

            <a href="https://yourgutmap.co.uk/">Home</a>
          
            <div className="aboutlist">
              <a href="https://yourgutmap.co.uk/about/">About</a>


            </div>
            <a href="https://yourgutmap.co.uk/practitioners/">Practitioners</a>
            <a href="https://yourgutmap.co.uk/vitamin-angels/">Vitamin Angels</a>
            <a href="https://academy.yourgutmap.co.uk/">Academy</a>
            <a href='https://yourgutmap.co.uk/shop/'> <button>Shop</button></a>
          </div>
        </div>

        <Routes>



          <Route path='/' element={<Index />} />
          <Route path='/:email' element={<Booking />} />
          <Route path='/teams/:catogory' element={<Seeteams />} />

          <Route path='/:email/:meetingID' element={<Meet />} />
          <Route path='/:email/:meetingID/info' element={<Info />} />

        </Routes>

        {/* <div className='footer'>
      <div className="footerheader">
    
        <div className="lista">
          <h1>About</h1>
          <ul>
            <li>Microbiome</li>
            <li>Food sensitvity</li>
            <li>science</li>
            <li>Shop</li>

          </ul>
        </div>
        <div className="lista">

          <h1>Connect</h1>
          <ul>

            <li>Contact</li>
            <li>Practitioners</li>

          </ul>
        </div>
        <div className="lista">
          <h1>Address</h1>
          <ul>

            <li>yourgutMap</li>
            <li>The Catalyst</li>
            <li>York Science Park</li>
            <li>York</li>
            <li>YO1O5GA</li>

          </ul>
        </div>
      </div>
      <div className="footerend">
        <div className='footerendleft'>
          <a href="">Privacy Policy</a>
          <a href="">Term & condition</a>


        </div>
        <div className="footerendright">
          <p>YourGutMap Copyright 2024-25 © All rights Reserved.</p>
        </div>

      </div>


    </div> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
